export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "cs": {
        "helpTranslate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nápověda k překladu Data Pulse"])}
      },
      "de": {
        "helpTranslate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilf Data Pulse zu übersetzen"])}
      },
      "es": {
        "helpTranslate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ayuda a traducir Data Pulse"])}
      },
      "fr": {
        "helpTranslate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aider à traduire Data Pulse"])}
      },
      "id": {
        "helpTranslate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bantu terjemahan pusat"])}
      },
      "it": {
        "helpTranslate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aiuta a tradurre Data Pulse"])}
      },
      "ja": {
        "helpTranslate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Pulseの翻訳に貢献する"])}
      },
      "sw": {
        "helpTranslate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saidia kutafsiri Data Pulse"])}
      }
    }
  })
}
